/* eslint-disable @typescript-eslint/no-explicit-any */
import { signStatus } from '../lib/signStatus'

const formatTrackName = (name: string) => `TIMELINE_${name}_BUTTON_CLICKED`

export interface Step {
  condition?: ((userContext: any, mandateStatus: any) => boolean) | boolean
  content: ((userContext: any, mandateStatus: any) => React.ReactNode) | React.ReactNode
  isCurrent: ((userContext: any, mandateStatus: any) => boolean) | boolean
  title: ((userContext: any, mandateStatus: any) => string) | string
  tracking?: { name: string }
}

const search: Step = {
  condition: ({ user: { projectKind } }) => projectKind === 'purchase',
  content: 'Vous êtes en recherche du bien que vous voulez acquérir.',
  isCurrent: ({ status }) => status === 'search',
  title: 'Trouver le bien',
}

const offerAccepted: Step = {
  condition: ({ user: { projectKind } }) => projectKind === 'purchase',
  content: "Vous avez fait une offre d'achat qui a été acceptée par le vendeur.",
  isCurrent: false,
  title: 'Signer le compromis',
}

const simulate: Step = {
  condition: ({ user: { projectKind } }) => projectKind === 'renegotiation',
  content: 'Vous évaluez les économies possibles sur votre prêt immo.',
  isCurrent: false,
  title: 'Simuler votre rachat',
}

const account: Step = {
  condition: ({ user: { projectKind } }) => projectKind === 'renegotiation',
  content: 'Vous accédez à votre espace sécurisé en ligne.',
  isCurrent: false,
  title: 'Créer un compte Pretto',
}

const project: Step = {
  content: 'Vous remplissez vos informations et envoyez vos documents.',
  isCurrent: ({ status }) => ['constitution', 'validation'].includes(status),
  title: 'Compléter votre dossier',
  tracking: { name: formatTrackName('APPLICATION') },
}

const projectValidation: Step = {
  content: "Il est important que votre dossier soit complet pour l'envoyer aux banques.",
  isCurrent: ({ status }) => status === 'analysis',
  title: 'Valider votre dossier',
}

const confirmProject: Step = {
  content: (userContext, mandateStatus) => {
    switch (signStatus({ ...userContext, mandateStatus })) {
      case 'NO_MANDATE':
      case 'DUO_AWAITING_BOTH':
      case 'SOLO_AWAITING':
        return 'Vérifiez les paramètres de votre projet et signez notre mandat.'

      case 'DUO_AWAITING_ME':
        return 'Votre co-emprunteur a signé, à vous de jouer !'

      case 'DUO_AWAITING_OTHER':
        return 'En attente que votre co-emprunteur signe son mandat.'

      case 'DUO_SIGNED':
      case 'SOLO_SIGNED':
        return 'Nos équipes vont contacter les banques d’ici peu.'

      case 'INVALID_MANDATE':
        return 'Il y a un problème avec votre mandat, veuillez contacter votre expert.'

      default:
        return 'Signez notre mandat de recherche de financement.'
    }
  },
  isCurrent: ({ status }) => status === 'confirmation',
  title: 'Signer votre mandat',
  tracking: { name: formatTrackName('MANDATE') },
}

const negotiation: Step = {
  content:
    'Pretto consulte les banques et négocie la meilleure offre pour vous. Le temps de réponse moyen est de 15 à 25 jours.',
  isCurrent: ({ status }) => status === 'negotiation',
  title: 'Négociation du prêt',
}

const agreement: Step = {
  content: ({ advisor }) =>
    advisor.role === 'expert'
      ? 'Votre expert vous présente la meilleure offre.'
      : 'Nous vous présentons la meilleure offre.',
  isCurrent: ({ status }) => status === 'agreement',
  title: 'Accord de principe',
}

const appointment: Step = {
  content:
    'Vous rencontrez votre banquier. Le délai de traitement avant de recevoir votre offre de prêt peut varier selon les banques. Comptez en général 2 à 4 semaines.',
  isCurrent: ({ status }) => status === 'appointment',
  title: 'Rendez-vous banque',
}

const signature: Step = {
  content: "Vous recevez l'offre de prêt et attendez 11 jours avant de la renvoyer signée.",
  isCurrent: ({ status }) => status === 'signature',
  title: 'Signer l’offre de prêt définitive',
}

const signed: Step = {
  content: ({ user: { projectKind } }) =>
    projectKind === 'purchase'
      ? 'Félicitations, il ne vous reste plus qu’à officialiser votre achat devant le notaire en signant l’acte authentique de vente.'
      : null,
  isCurrent: ({ status }) => status === 'signed',
  title: 'Vous avez signé l’offre de prêt',
}

const end: Step = {
  content: ({ user: { projectKind } }) =>
    projectKind === 'purchase'
      ? 'Vous signez chez le notaire. Et voilà, vous êtes propriétaire !'
      : 'Vous remboursez votre crédit à votre nouvelle banque.',
  isCurrent: ({ status }) => status === 'end',
  title: ({ user: { projectKind } }) =>
    projectKind === 'purchase' ? "C'est terminé !" : 'Vous faites des économies !',
}

export const timeline = [
  search,
  offerAccepted,
  simulate,
  account,
  project,
  projectValidation,
  confirmProject,
  negotiation,
  agreement,
  appointment,
  signature,
  signed,
  end,
]
